import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Newsdetails from './components/pages/newsdetails';
import SinglePage from './components/pages/SinglePage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import  gtag  from 'ga-gtag';
import ModalComponent from './components/popmodel/ModalComponent';


function App() {
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
		  const isModalShown = sessionStorage.getItem('isModalShown');
		  if (!isModalShown) {
			setIsModalOpen(true);
			sessionStorage.setItem('isModalShown', 'true');
		  }
		}, 1000); 
	
		return () => clearTimeout(timer);
	  }, []);


	  const handleCloseModal = () => {
		setIsModalOpen(false);
	  };

	const handleWhatappGtag = () =>{        
        gtag('event', 'Floating Whatsapp', {
            "section_name": "Floating Whatsapp Icon"
        });
    }
	return (
		<Router basename={'/'}>
			<Routes>
				<Route path="/news/:newsurl" element={<Newsdetails />} />
				<Route path='*' element={<SinglePage />} />
			</Routes>
			<a
				href="https://wa.me/917303950845?text=hello"
				className="whatsapp_float"
				target="_blank"
				rel="noopener noreferrer"
				onClick={handleWhatappGtag}
			>
				<div className="whatsapp_icon_wrapper">
					<FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
				</div>
				
			</a>
			<ModalComponent isOpen={isModalOpen} onClose={handleCloseModal} />
		</Router>
	);
}
export default App;
